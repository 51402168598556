import { render, staticRenderFns } from "./FormulateLabel.vue?vue&type=template&id=53d96a0a&"
import script from "./FormulateLabel.js?vue&type=script&lang=js&"
export * from "./FormulateLabel.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/mijnmooiestraat.vlaanderen/beta.mijnmooiestraat.vlaanderen/pro-beta.mijnmooiestraat.vlaanderen/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('53d96a0a')) {
      api.createRecord('53d96a0a', component.options)
    } else {
      api.reload('53d96a0a', component.options)
    }
    module.hot.accept("./FormulateLabel.vue?vue&type=template&id=53d96a0a&", function () {
      api.rerender('53d96a0a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/components/Formulate/FormulateLabel/FormulateLabel.vue"
export default component.exports